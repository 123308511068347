<template>
  <el-dialog
      title="物流轨迹"
      width="60%"
      append-to-body
      top="8vh"
      :visible.sync="dialogVisible"
      @close="close">
    <div class="track-info">
      <div>
        <span>寄件人</span>
        {{expressNo.senderName}}
      </div>
      <div>
        <span>联系方式</span>
        {{ expressNo.senderMobile }}
      </div>
      <div>
        <span>发货地址</span>
        {{ expressNo.senderAddress }}
      </div>
      <div>
        <span>快递公司</span>
        {{expressNo.logisticsCompany}}
      </div>
      <div>
        <span>快递单号</span>
        {{expressNo.waybillNo}}
      </div>
      <div>
        <span>预计送达时间</span>
        {{deliveryPromiseTimeStr?deliveryPromiseTimeStr:''}}
        <el-button v-if="!deliveryPromiseTimeStr" :loading="loading" type="primary" size="small" @click="estimatedTimeIng" style="margin-left: -10px">查询</el-button>
      </div>
    </div>
    <el-table
        v-if="expressNo.queryType === '0'"
        border
        :data="trackInfoList"
        :header-cell-style="{background:'#F3F4F7',color:'#555',padding: '5px 0'}"
        :cell-style="{padding: '5px 0'}">
      <el-table-column
          label="序号"
          type="index"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="opeTime"
          label="时间"
          align="center"
          width="300">
      </el-table-column>
      <el-table-column
          prop="opeRemark"
          label="物流轨迹"
          align="center">
      </el-table-column>
    </el-table>
    <webview v-else :src="src" style="width: 100%; height: 500px;"></webview>
  </el-dialog>
</template>

<script>
import {erp2OperatingReceiveTraceGet,QueryEstimatedDeliveryTimeByExpressSn} from '@/libs/http/modules/erp.js'
export default {
  name: 'trackDialog',
  props: ['expressNo'],
  data(){
    return{
      dialogVisible: false,
      trackInfoList: [],
      receiverAddress: '',
      receiverPhone: '',
      src: '',
      loading:false,
      deliveryPromiseTimeStr:"",
    }
  },
  created(){
    this.dialogVisible = true
    if(this.expressNo.queryType === '0'){
      this.fetchLogis()
    }else{
      if(this.expressNo.queryUrl){
        this.src = this.expressNo.queryUrl + this.expressNo.waybillNo
      }else{
        this.src = `https://www.yto.net.cn/tracesimple.html/?orders=${this.expressNo.waybillNo}`
      }
    }
  },
  methods: {
    close(){
      this.dialogVisible = false
      this.$emit('close')
    },
    estimatedTimeIng () {
      this.loading = true
      QueryEstimatedDeliveryTimeByExpressSn({
        expressSn: this.expressNo.waybillNo,
      },).then(res => {
        this.loading = false
        if(res.code=='0') {
          this.deliveryPromiseTimeStr = res.data.deliveryPromiseTimeStr
        }else{
          this.$message({ type: 'error', message: res.msg, duration: 2000, showClose: true })
        }
      }).catch(err => {
        this.loading = false
        this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
      })
    },
    fetchLogis(){
      erp2OperatingReceiveTraceGet({
        waybillCode: this.expressNo.waybillNo,
        logisticsId: this.expressNo.logisticsCompanyId
      }).then(data=>{
        if(data){
          this.trackInfoList = data.items || []
        }
      }).catch(error=>{
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.track-info{
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-bottom: 20px;
  div{
    color: #000;
    width: calc(100% / 3);
    display: flex;
    span{
      width:120px;
      color: #666;
    }
  }
}
</style>
